import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { IncreaseModule } from '@dev-fast/ui-components/increase/increase.component';

import { CurrencyComponent } from '@app/core/currency/components/currency/currency.component';
import { HashPipe } from '@app/shared/pipes';

@Component({
  selector: 'app-case-card-new',
  standalone: true,
  templateUrl: './case-card-new.component.html',
  styleUrl: './case-card-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IncreaseModule, CurrencyComponent, MatIcon, MatProgressSpinner, HashPipe],
})
export class CaseCardNewComponent {
  readonly name = input<string>();
  readonly price = input<number>();
  readonly chanceCount = input<number>();
  readonly isPlaceholder = input<boolean>();
  readonly isLoading = input<boolean>();
  readonly isDisabled = input<boolean>();
  readonly image = input<string>();
  readonly currencyIconName = input<string>();
  readonly tags = input<string[]>();

  readonly clickOnCase = output();
}
