@if (isPlaceholder()) {
  <div class="case-card placeholder tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-h-full">
    @if (isLoading()) {
      <div class="loading-gradient tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0"></div>
    }
    <mat-icon
      svgIcon="case-placeholder-v2"
      class="placeholder__icon tw-w-full tw-h-fit"
    ></mat-icon>
  </div>
} @else {
  <div
    class="case-card tw-flex tw-flex-col tw-justify-between tw-relative tw-overflow-hidden tw-cursor-pointer tw-h-full tw-events"
    [class.disabled]="isDisabled()"
    (click)="clickOnCase.emit()"
  >
    @if (isLoading()) {
      <div class="loading-gradient tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0"></div>
    } @else {
      <div class="case-card__top tw-flex tw-gap-1 tw-justify-between tw-relative tw-z-[1] tw-uppercase">
        <div class="case-card__tag case-card__name tw-overflow-hidden tw-text-ellipsis tw-font-semibold tw-rounded-lg">
          {{ name() }}
        </div>
        @if (chanceCount()) {
          <app-ui-increase
            [valueType]="'percent'"
            [setCurValue]="chanceCount()!"
            class="case-card__percent"
          />
        } @else {
          @for (tag of tags(); track $index) {
            @if (tag) {
              <div class="case-card__tag case-card__lable tw-font-semibold tw-rounded-lg tw-uppercase">
                {{ tag }}
              </div>
            }
          }
        }
      </div>
      <img
        [src]="image()"
        alt=""
        class="case-card__image tw-z-0"
      />
      <div class="case-card__price tw-flex tw-items-center tw-justify-center tw-font-medium tw-mx-auto tw-relative tw-z-[1]">
        <app-currency
          [value]="price()"
          [customIcon]="currencyIconName()"
          class="currency"
        >
        </app-currency>
      </div>
    }
  </div>
}
